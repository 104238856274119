import React from "react";
import Section from "../common/Section";
import { H3 } from "../common/Typography";
import imageTeachers from "../../assets/images/teachers.jpg";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { TEACHERS } from "../../data";
import ChevronLeft from "../../assets/icons/chevron-left.svg";
import ChevronRight from "../../assets/icons/chevron-right.svg";
import IconSquare from "../common/IconSquare";
import { MEDIA_QUERIES } from "../../config";

SwiperCore.use([Navigation]);

function Teachers() {
  return (
    <Section
      css={`
        padding: 80px 0 96px 0;
        border-bottom: 1px solid #000;

        ${MEDIA_QUERIES.mobile} {
          padding: 0;
          border: none;
        }
      `}
    >
      <H3
        css={`
          margin-bottom: 48px;

          ${MEDIA_QUERIES.mobile} {
            margin-left: 16px;
            margin-bottom: 32px;
          }
        `}
      >
        Καθηγητές
      </H3>
      <div
        css={`
          display: grid;
          grid-template-columns: 349px 500px;
          gap: 87px;

          img {
            width: 100%;
            height: auto;
          }

          ${MEDIA_QUERIES.mobile} {
            grid-template-columns: 375px;
            gap: 0;

            img {
              width: 100vw;
            }
          }
        `}
      >
        <img src={imageTeachers} alt="Teachers" />
        <div
          css={`
            ${MEDIA_QUERIES.mobile} {
              margin: 32px 0;
            }
          `}
        >
          <Swiper
            loop
            autoHeight
            slidesPerView={1}
            navigation={{
              nextEl: ".swiper-next",
              prevEl: ".swiper-prev",
            }}
          >
            {TEACHERS.map(teacher => (
              <SwiperSlide key={teacher.name}>
                <Slide {...teacher} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            css={`
              display: flex;
              margin-top: 38px;

              ${MEDIA_QUERIES.mobile} {
                padding: 0 16px;
                margin-top: 16px;
              }
            `}
          >
            <IconSquare className="swiper-prev">
              <ChevronLeft />
            </IconSquare>
            <IconSquare
              className="swiper-next"
              css={`
                border-left: none;
              `}
            >
              <ChevronRight />
            </IconSquare>
          </div>
        </div>
      </div>
    </Section>
  );
}

function Slide({ name, description }) {
  return (
    <div
      css={`
        ${H3} {
          margin-bottom: 24px;
        }

        ${MEDIA_QUERIES.mobile} {
          padding: 0 16px;
        }
      `}
    >
      <H3>{name}</H3>
      {description}
    </div>
  );
}

export default Teachers;

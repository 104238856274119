import styled from "styled-components";

const IconSquare = styled.button`
  width: 43px;
  height: 43px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  outline: none;

  &:active {
    svg {
      width: 10px;
    }
  }

  svg {
    width: 8px;
    height: auto;
  }
`;

export default IconSquare;

import React from "react";
import { MEDIA_QUERIES } from "../../config";
import Section from "../common/Section";
import { H2, H3, P } from "../common/Typography";
import image from "../../assets/images/musical-theater-introduction.jpg";

function Introduction() {
  return (
    <Section
      css={`
        display: flex;
        padding: 96px 0;
        border-bottom: 1px solid #000;

        img {
          max-width: 386px;
        }

        ${MEDIA_QUERIES.mobile} {
          flex-direction: column;
          align-items: center;
          padding: 0;
          border: none;

          img {
            max-width: 100vw;
          }
        }
      `}
    >
      <div>
        <img src={image} alt="Athens Dance School Musical Theater" />
      </div>
      <div
        css={`
          padding-left: 50px;

          ${H2} {
            margin-bottom: 16px;
          }

          ${H3} {
            margin-bottom: 24px;
          }

          ${MEDIA_QUERIES.mobile} {
            padding-left: unset;
            padding: 32px 16px;
          }
        `}
      >
        <H2>Musical Theater</H2>
        <H3>ONE YEAR COURSE</H3>
        <P>
          Μάθε τις τεχνικές και τα μυστικά του MUSICAL THEATRE από τους
          καλύτερους του είδους.
        </P>
        <P>
          Σπουδές μονοετούς φοίτησης στις οποίες ο Θοδωρής Πανάς με τον Δημήτρη 
          Μαλισσόβα μετά από 9 χρόνια επιτυχούς λειτουργίας, εμπλουτίζουν 
          με νέα μαθήματα και εντάσσουν καθηγητές που ο καθένας ξεχωριστά 
          διαπρέπει στον τομέα του.
        </P>
        <P>
          Σκοπός μας, η διδασκαλία του μουσικού θεάτρου να εστιάζει στη δομή και
          τη φόρμα του, τις τεχνικές και τα μυστικά του. Με 60 ώρες διδασκαλίας
          το μήνα, φτιάχνουμε τον μελλοντικό Musical Performer, έτοιμο ν'
          ανταποκριθεί στις δύσκολες απαιτήσεις του μουσικού θεάτρου, χορευτικά
          και υποκριτικά.
        </P>
      </div>
    </Section>
  );
}

export default Introduction;

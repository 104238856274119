import React from "react";
import Layout from "../components/common/Layout";
import SEO from "../components/common/SEO";
import Diplomas from "../components/musical-theater/Diplomas";
import Introduction from "../components/musical-theater/Introduction";
import Lessons from "../components/musical-theater/Lessons";
import Teachers from "../components/musical-theater/Teachers";

function MusicalTheaterPage() {
  return (
    <Layout>
      <SEO title="Musical Theater" />

      <main>
        <Introduction />
        <Teachers />
        <Lessons />
        <Diplomas />
      </main>
    </Layout>
  );
}

export default MusicalTheaterPage;

import React from "react";
import { LESSONS } from "../../data";
import Section from "../common/Section";
import Button from "../common/Button";
import { H3 } from "../common/Typography";
import { MEDIA_QUERIES } from "../../config";
import { Link } from "gatsby";
import toGreeklishSlug from "../../utils/toGreeklishSlug";

function Lessons() {
  return (
    <Section
      css={`
        padding-top: 96px;
        border-bottom: 1px solid #000;
        display: grid;
        grid-template-columns: 175px 1fr;
        gap: 86px;

        ${H3} {
          line-height: 1.33;
        }

        ${MEDIA_QUERIES.mobile} {
          grid-template-columns: 1fr;
          padding: 32px 16px 0 16px;
          gap: 32px;
          border: none;
          margin-bottom: 0;
        }
      `}
    >
      <H3>Βιογραφικά Καθηγητών & Μαθήματα</H3>
      <div>
        <div>
          {LESSONS.map(lesson => (
            <Button
              as={Link}
              to={`/team/${toGreeklishSlug(lesson.teacher)}`}
              css={`
                display: block;
                &:hover {
                  padding-left: 8px;
                }
              `}
              fluid
              key={lesson.teacher}
            >
              <div
                css={`
                  display: flex;
                  align-items: center;
                `}
              >
                <span>{lesson.teacher}</span>
                <span
                  css={`
                    margin: 0 10px;
                  `}
                >
                  |
                </span>
                <span
                  css={`
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.57;
                  `}
                >
                  {lesson.lessons}
                </span>
              </div>
            </Button>
          ))}
        </div>
        <Button.Arrowed
          as={Link}
          to="/program"
          style={{ margin: "56px 0", float: "right", width: 238 }}
        >
          Δείτε το πρόγραμμα
        </Button.Arrowed>
      </div>
    </Section>
  );
}

export default Lessons;

import React from "react";
import { MEDIA_QUERIES } from "../../config";
import Section from "../common/Section";
import { H3, P } from "../common/Typography";

function Diplomas() {
  return (
    <Section
      css={`
        margin-bottom: 136px;
        margin-top: 96px;
        ${H3} {
          margin-bottom: 24px;
        }

        ${MEDIA_QUERIES.mobile} {
          padding: 0 16px;
        }
      `}
    >
      <H3>ΔΙΠΛΩΜΑΤΑ CID</H3>
      <P>
        Η σχολή "ΘΟΔΩΡΗΣ ΠΑΝΑΣ / ΣΧΟΛΗ ΧΟΡΟΥ ΑΘΗΝΩΝ" και το τμήμα του MUSICAL
        THEATRE είναι εγγεγραμμένα στο διεθνή οργανισμό του CID - Consell
        International de la Dance, που έχει έδρα στο Παρίσι και λειτουργεί από
        το 1973 κάτω από την αιγίδα της UNESCO.
      </P>
      <P>
        Το CID είναι αναγνωρισμένο σε 170 χώρες του κόσμου και αριθμεί πάνω από
        10.000 μέλη. Για περισσότερες πληροφορίες σχετικά με το CID,
        επισκεφθείτε την ιστοσελίδα του: <a href="http://www.cid-worg.org" target="_blank" rel="noopener noreferrer">www.cid-worg.org.</a>
      </P>
      {/* <P>
        Επισκεφθείτε την ιστοσελίδα του CID www.cid-worg.org, για περισσότερες
        πληροφορίες.
      </P> */}
      <P>
        International Dance Council CID <br />
        (The United Nations of Dance) <br /> CID, UNESCO, 1 rue Miollis, FR -
        75732 Paris, France
      </P>
      {/* <P>
        Το Τμήμα του MUSICAL THEATRE "Techniques and Secrets" λειτουργεί τις
        ημέρες: <br />
        <table>
          <tr>
            <td>ΔΕΥΤΕΡΑ</td>
            <td>15:30 - 19:00</td>
          </tr>
          <tr>
            <td>ΤΡΙΤΗ - ΠΕΜΠΤΗ</td>
            <td>15:00 - 19:00</td>
          </tr>
          <tr>
            <td>ΠΑΡΑΣΚΕΥΗ</td>
            <td>15:00 - 18:30</td>
          </tr>
        </table>
        Υπαρχει δυνατότητα παρακολούθησης extra μαθημάτων οπως Μπαλέτο, Σύγχρονο και Latin.
      </P> */}
      <P>
        Για περαιτέρω πληροφορίες απευθυνθείτε στη Γραμματεία της Σχολής από{" "}
        <br />
        Δευτέρα - Παρασκευή: 16.00-21.00 <br />
        Σάββατο: 11.00-16.00
      </P>
    </Section>
  );
}

export default Diplomas;
